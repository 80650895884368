body {
  font-size: 14px;

  @media screen and (min-width: 450px) {
    font-size: 16px;
  }
}

button {
  background-color: #5b9bd5;
  color: white;
  border: none;
  cursor: pointer;

  @media screen and (min-width: 1030px) {
    font-size: 16px;
    padding: 10px;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
  border: none;
}

th,
td {
  border: 1px solid #ffffff;
  text-align: left;
  padding: 2px;
}

th {
  background-color: #5b9bd5;
  color: #ffffff;
  text-align: center;
  line-height: 1.2;
  padding-top: 2px;
  padding-bottom: 2px;
}

tr {
  background-color: #eaeff7;
}

tr:nth-child(even) {
  background-color: #d2deef;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
